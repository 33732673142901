:root {
  --text-primary: #454545;
  --text-secondary: #280749;
  --text-awarness: #ffffff;
  --bg-primary: #5271ff;
  /* --bg-primary: #065fb7; */
  --bg-secondary: #d6c7a8;
  --bg-awarness: #dd14f7;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

html,
body {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(180, 35, 35, 0);
  background-color: #f9f9f9;
}

::selection {
  background-color: var(--bg-primary);
  color: #ffffff;
}

h1 {
  font-size: 26px;
  font-weight: 600;
}

h2 {
  font-size: 22px;
  font-weight: 600;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

p {
  font-size: 18px;
  font-weight: 400;
  color: #454545;
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: #454545;
}

.app__container {
  background-color: var(--bg-1);
  width: 100%;
  min-height: 60vh;
  margin-top: -75px;
}

.app__section {
  padding-top: 25px;
  padding-bottom: 25px;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.w-100 {
  max-width: 100% !important;
  width: 100% !important;
}
.p-top {
  padding-top: 75px;
}

.br-on {
  border-radius: 18px;
}

.app__form_br {
  border-radius: 10px;
}

.btn-br-on {
  border-radius: 10px;
}

.shadow-on {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.app__global_form_wrapper {
  padding: 10px;
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.app__global_form_wrapper h2 {
  font-size: 32px;
  font-weight: 600;
  color: #065fb7;
  text-align: center;
}

/* .app__user_page_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 75px;
  padding-bottom: 25px;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
} */

.app__user_page_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 60vh;
  /* background-color: lightcoral; */
  margin-top: 75px;
}

.app__user_page_content {
  max-width: 1440px;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  min-height: 60vh;
  align-items: flex-start;
  /* background-color: lightblue; */
}

.app__user_page_content h2 {
  color: #353535;
}
